<template>
  <div class="order-confirm">
    <van-row type="flex">
      <van-col span="24">
        <van-cell-group :border="false" class="delivery mt-3">
          <van-cell>
            <template #title>
              <div class="title">
                <span class="cell-group-title">配送方式</span>
                <van-radio-group v-model="delivery" direction="horizontal" @change="changeExpress">
                  <van-radio name="onSpot" checked-color="#FF2020" icon-size="16px">现场自提</van-radio>
                  <van-radio name="express" checked-color="#FF2020" icon-size="16px">快递运输</van-radio>
                </van-radio-group>
              </div>
            </template>
          </van-cell>
          <van-cell is-link center @click="chooseAddress" v-show="delivery === 'express'">
            <template #title>
              <div class="address">
                <div class="user">
                  <template v-if="receiveAddress.id">
                    <span>{{receiveAddress.name}}</span><span>{{receiveAddress.phoneNumber}}</span>
                  </template>
                  <template v-else>
                    暂无地址
                  </template>

                </div>
                <div class="address-detail">
                  <template v-if="receiveAddress.id">
                    <div class="tag">地址：</div>
                    <div class="detail">{{receiveAddress.provinceName + receiveAddress.cityName + receiveAddress.regionName + receiveAddress.detailAddress}}</div>
                  </template>
                  <template v-else>
                    请点击右侧箭头添加地址
                  </template>
                </div>

              </div>
            </template>
          </van-cell>
        </van-cell-group>
      </van-col>
      <van-col span="24">
        <div class="order-item-wrap mt-3">
          <div class="order-merchant mt-3" >
<!--            v-show="item.merchantId && item.merchantName"-->
            <div class="merchant" v-if="merchant.id && merchant.name">
              <van-icon :name="require('@/assets/images/order/shop.png')" size="20"/>
              {{merchant.name}}
            </div>
            <div class="card-wrap">
              <van-card
                  :price="Number(product.price).toFixed(2)"
                  :desc="productAttr(product.productAttr)"
                  :title="product.productName"
                  :thumb="product.productPic"
              />
              <div class="card-num">
                x{{product.quantity}}
              </div>
            </div>


          </div>
        </div>
      </van-col>
      <van-col span="24">
        <div class="total-wrap mt-3">
          <van-cell-group :border="false">
            <van-cell :border="false" title="商品原价">
              ￥{{formatPrice(product.originalPrice)}}
            </van-cell>
            <van-cell :border="false" title="优惠价">
              ￥{{formatPrice(product.price)}}
            </van-cell>
            <van-cell :border="false" title="运费">
              ￥{{formatPrice(freight)}}
            </van-cell>

            <van-cell :border="false" >
              <template #title>&nbsp;</template>
              <div class="product-total">
                合计：<span class="price">￥{{formatPrice(total)}}</span>
              </div>
            </van-cell>
          </van-cell-group>
        </div>
      </van-col>
    </van-row>

    <van-submit-bar safe-area-inset-bottom :price="totalPrice" button-text="提交订单" @submit="onSubmit" button-color="#FF2020"/>
    <loading-overlay :loading="loading"/>
    <wechat/>
  </div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import {Card, Cell, CellGroup, Col, Divider, Icon, Image, Radio, RadioGroup, Row, SubmitBar, Toast} from 'vant'
import {confirmOrder, generateDirectBuyOrder, unifiedorder} from "@/api/order";
import LoadingOverlay from "@/components/LoadingOverlay";
import Wechat from "@/components/Wechat";
import wx from 'weixin-js-sdk';
import {isInWeixin} from '@/utils/helper'

Vue.use(Row)
Vue.use(Col)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Image)
Vue.use(Card)
Vue.use(SubmitBar)
Vue.use(Toast)
Vue.use(Divider)
Vue.use(CellGroup)
Vue.use(Cell)
Vue.use(Icon)

const defaultReceiveAddress = {
  cityCode:null,
  cityName:null,
  defaultStatus:null,
  detailAddress:null,
  gender:null,
  id:null,
  memberId:null,
  name:null,
  phoneNumber:null,
  postCode:null,
  provinceCode:null,
  provinceName:null,
  regionCode:null,
  regionName:null,
}

const defaultCartProduct = {}

export default {
  components: {LoadingOverlay, Wechat},
  data() {
    return {
      loading: false,
      delivery: 'onSpot', // 配送方式
      addressId: undefined, // 地址id
      total: '', // 订单合计
      orderItems: [], //订单信息
      receiveAddress: Object.assign({}, defaultReceiveAddress),
      confirmKey:'',
      orderId:''
    }
  },
  computed:{
    ...mapGetters({
      productId: 'getProductId',
      model: 'getModel',
      invitationCode: 'getInvitationCode',
      productSkuId: 'getSkuId',
      quantity: 'getQuantity',
    }),
    totalPrice(){
      return this.formatPrice(this.total)*100
    },
    freight(){
      if(this.orderItems && this.orderItems.length>0){
        return this.orderItems[0].freight
      }else{
        return 'N/A'
      }
    },
    product(){
      let product = Object.assign({}, defaultCartProduct)
      if(this.orderItems && this.orderItems.length>0 && this.orderItems[0]['cartItems'] && this.orderItems[0]['cartItems'].length){
        const cartItem = this.orderItems[0]['cartItems'][0]
        product = Object.assign(product, cartItem)
      }

      return product
    },
    merchant(){
      let merchant = {name:'', id:null}
      if(this.orderItems && this.orderItems.length>0 && this.orderItems[0]['merchantId']){
        merchant.id = this.orderItems[0]['merchantId']
        merchant.name = this.orderItems[0]['merchantName']
      }
      return merchant

    }
  },
  filters:{

  },
  methods: {

    initPage(){
      if(this.$route.query){
        this.$route.query.addressId && (this.addressId = this.$route.query.addressId)
        this.$route.query.delivery && (this.delivery = this.$route.query.delivery)
      }
      if(this.$route.query && this.$route.query.id){
        this.$store.dispatch('updateProduct', this.$route.query).then(()=>{
          this.confirmOrderHandle()
        })
      }else{
        this.confirmOrderHandle()
      }
    },

    confirmOrderHandle(){
      this.loading = true
      const confirmData = {
        productId: this.productId,
        productSkuId: this.productSkuId,
        quantity: this.quantity,
        delivery: this.delivery,
        addressId: this.delivery === 'onSpot' ? undefined: this.addressId,
      }
      this.total = null
      this.confirmKey = null
      this.orderItems = []
      confirmOrder(confirmData).then(res=>{
        this.loading = false
        if(res.code === 200){
          this.orderItems = res.data.items
          this.total = res.data.total
          this.confirmKey = res.data.confirmKey
          this.initDefaultAddressId(res.data.receiveAddressList)
        }else{
          Toast.fail(res.message||'确认订单信息失败');
        }
      }, err => {
        this.loading = false
        console.log(err)
        Toast.fail('确认订单信息出错');
      })

    },
    productAttr(attrs){
      if(!attrs){
        return ''
      }
      let attrDesc = ''
      const attrObj = JSON.parse(attrs)
      if(attrObj){
        const descArr = attrObj.map(item=>{return item.value})
        if(descArr){
          attrDesc = descArr.join(',')
        }
      }
      return attrDesc
    },
    initDefaultAddressId(addressList){
      if(!addressList){
        this.addressId = null
        return
      }
      if(!this.addressId){
        const defaultAddress = addressList.find(address=>{
          return !!address.defaultStatus
        })
        if(defaultAddress){
          this.addressId = defaultAddress.id
          this.receiveAddress = Object.assign({}, defaultReceiveAddress, defaultAddress)
          /*if(this.quantity = 'express'){

          }*/
          this.confirmOrderHandle()
        }
      }else {
        const address = addressList.find(item=>{
          return item.id = this.addressId
        })
        if(address){
          this.receiveAddress = Object.assign({}, defaultReceiveAddress, address)
        }
      }
    },
    formatPrice(value){
      if (!value || isNaN(value)) {
        value = 0
      }
      value = Number(value).toFixed(2);
      /*if (value === 0) {
        return 0
      }*/
      //console.log(value)
      /*if (value.split('.')[1].substring(1) === '0') {
        value = Number(value).toFixed(1);
      }*/
      return value;
    },
    // 配送方式
    changeExpress() {
      this.$store.dispatch('updateDelivery', this.delivery)
      if(this.addressId){
        this.confirmOrderHandle()
      }

    },
    // 选择地址
    chooseAddress() {
      this.$router.push({path: '/addressList'});
    },
    goOrderDetail(){
      this.$router.replace({name:'payWait', params:{orderId: this.orderId}})
    },
    goPayResult(){
      this.$router.replace({name:'payResult', params:{orderId: this.orderId}})
    },
    doPayOrder(params){
      if(!isInWeixin()){
        Toast.fail('请在微信中打开支付');
        return false
      }
      wx.chooseWXPay({
        timestamp: params.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: params.nonceStr, // 支付签名随机串，不长于 32 位
        package: params.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: params.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
        paySign: params.paySign, // 支付签名
        success: (res)=> {
          console.log(res)
          // 支付成功后的回调函数
          Toast.success('支付成功');
          this.goPayResult()
        },
        cancel:(cancel)=>{
          console.log(cancel)
          Toast.fail('支付取消')
          this.goOrderDetail()
        },
        fail: (err)=> {
          console.log(err)
          Toast.fail(err.errMsg||'支付失败')
          this.goOrderDetail()
        }
      })
    },
    preUnifiedorder() {
      this.loading = true
      unifiedorder(this.orderId).then(res => {
        this.loading = false
        if (res.code === 200) {
          console.log(res)
          const {timestamp, nonceStr, signType, sign} = res.data
          const params = {timestamp, nonceStr, package:res.data.package, signType, paySign: sign}
          this.doPayOrder(params)
        } else {
          Toast.fail(res.message ||'发起支付失败')
          this.goOrderDetail()
        }
      }, error => {
        this.loading = false
        console.log(error)
        Toast.fail('发起支付出错');
      })
    },
    onSubmit() {
      const orderData = {
        confirmKey: this.confirmKey,
        delivery: this.delivery,
        memberReceiveAddressId: this.delivery === 'onSpot' ? undefined: this.addressId,
        source: 'h5'
      }
      this.loading = true
      generateDirectBuyOrder(orderData).then(res=>{
        this.loading = false
        if(res.code === 200&& res.data.order && res.data.order.id){
          //console.log(res)
          this.orderId = res.data.order.id
          this.preUnifiedorder()


        }else{
          Toast.fail(res.message ||'提交订单失败')
          return false
        }
      }, error => {
        this.loading = false
        console.log(error)
        Toast.fail('提交订单出错');
      })
    },

  },
  created() {
    this.initPage();
  },
  mounted() {
  }
}
</script>

<style lang="sass" scoped>
.order-confirm
  .delivery
    border-radius: 5px
    background-color: #fff
    .van-cell
      background-color: transparent
    .title
      display: flex
      flex: 1
      flex-direction: row
      justify-content: space-between
    .address
      display: flex
      flex: 1
      flex-direction: column
      font-size: 14px
      .user, .address-detail
        display: flex
        flex: 1
      .user
        color: #333333
        span
          margin-right: 15px
      .address-detail
        color: #666666
        justify-content: flex-start
        align-items: flex-start
        .tag
          margin-right: 10px
        .detail
          display: flex
          flex: 1

  .order-item-wrap
    display: flex
    flex: 1
    flex-direction: column
    background-color: #fff
    border-radius: 5px
    .order-merchant
      .card-wrap
        display: flex
        flex-direction: row
        justify-content: space-between
        .van-card
          background-color: #ffffff
          .van-card__header
            .van-card__content
              .van-card__title
                font-size: 14px
                line-height: 18px
              .van-card__desc
                font-size: 13px
                margin-top: 2px
                color: #999999
              .van-card__bottom
                .van-card__price
                  color: #333333
                  font-weight: 400
                  .van-card__price-currency .van-card__price-decimal
                    font-size: 16px
                  .van-card__price-integer
                    font-size: 18px
        .card-num
          color: #999999
          font-size: 13px
          display: flex
          justify-content: center
          align-items: center
          flex-direction: column
          margin-right: 16px




      .merchant
        font-size: 14px
        font-weight: 400
        color: #333333
        padding: 8px 16px 0 16px
        display: flex
        align-items: center
        .van-icon
          margin-right: 10px
  .total-wrap
    border-radius: 5px
    padding: 5px 0
    background-color: #fff
    .van-cell
      padding-top: 2px
      padding-bottom: 1px
    .product-total
      font-size: 14px
      color: #333333
      .price
        color: #FF0000
        font-weight: 400

</style>
